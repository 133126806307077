@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap");
:root {
  --color-1: #3a5533;
  --color-2: #f58a21;
  --color-3: #643405;
  --color-4: #3d3d3d;
  --color-5: #66e546;
}

.bg-1 {
  background: var(--color-1);
}

.bg-2 {
  background: var(--color-2);
}

.bg-3 {
  background: var(--color-3);
}

.bg-4 {
  background: var(--color-4);
}

.bg-5 {
  background: var(--color-5);
}

.textcolor-primary {
  color: var(--color-1) !important;
}

.textcolor-secondary {
  color: var(--color-2) !important;
}

.textcolor-3 {
  color: var(--color-3);
}

.textcolor-4 {
  color: var(--color-4);
}

.textcolor-5 {
  color: var(--color-5);
}

.rotate180 {
  transform: rotateX(180deg) rotateY(180deg);
}

body {
  font-family: "Rubik", sans-serif !important;
  margin-top: calc(2vw + 100px) !important;
}

@media screen and (min-width: 280px) and (max-width: 767.9px) {
  body {
    margin-top: calc(5vw + 3.82rem) !important;
  }
}
a {
  text-decoration: none;
}

nav {
  padding: 0 1rem !important;
}

nav .collapsing {
  height: 100% !important;
}

.nav-item {
  padding: 0 1rem;
}

.nav-contact-cta {
  padding-left: 3rem;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--color-2) !important;
}

.button-primary {
  background: var(--color-2) !important;
  color: white !important;
  border-radius: 0 !important;
}

.button-primary-outline {
  border: 3px solid var(--color-2) !important;
  background: white !important;
  border-radius: 0px !important;
}

.button-primary-outline:hover {
  border: 3px solid var(--color-2) !important;
  border-radius: 0px !important;
  color: white !important;
  background: var(--color-2) !important;
}

.hero {
  background-repeat: no-repeat !important;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 50vw;
  color: white;
  position: relative;
}

.hero .hero-title-top {
  font-size: 0.8em;
  font-weight: 500;
}

.hero .hero-title {
  font-size: 4.11vw;
  font-weight: 900;
  line-height: 4.11vw;
  color: var(--color-2);
}

.hero .hero-title span {
  color: white;
}

.hero .hero-cta {
  color: #273044 !important;
}

.philosophy {
  background: var(--color-1);
  color: white;
  position: relative;
}

.philosophy .phil-overlay {
  position: absolute;
  top: 42vw;
}

.philosophy .phil {
  padding: 100px 1rem 120px 1rem;
}

.philosophy .phil .phil-left .phil-left-inner-top img {
  height: 40vw;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.philosophy .phil .phil-right {
  padding-left: 5vw;
}

.philosophy .phil .phil-right .phil-right-title {
  position: relative;
}

.philosophy .phil .phil-right .phil-right-title img {
  height: 30px;
}

.philosophy .phil .phil-right .phil-right-title .phil-right-title-text {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 0;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.philosophy .phil .phil-right .phil-body {
  padding: 2rem 0;
}

.philosophy .phil .phil-right .heading {
  text-transform: uppercase;
  font-weight: 800;
  margin: 2rem 0 1rem 0;
}

.philosophy .phil .phil-right .phil-cta .button-primary-outline {
  color: white;
}

.packages,
.t-packages {
  background: url("/public/assets/img/package-bg.png");
  color: white;
}

.packages h2,
.t-packages h2 {
  font-weight: 900 !important;
}

.packages .slick-slider .slick-prev:before,
.packages .slick-slider .slick-next:before,
.t-packages .slick-slider .slick-prev:before,
.t-packages .slick-slider .slick-next:before {
  font-family: "slick";
  font-size: 30px !important;
  color: black !important;
}

.packages .slick-slider .slick-next,
.t-packages .slick-slider .slick-next {
  right: -20px !important;
}

.packages .packages-item,
.t-packages .packages-item {
  padding: 2rem;
}

.packages .packages-item img,
.t-packages .packages-item img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 25px;
}

.packages .packages-item .packages-item-inner,
.t-packages .packages-item .packages-item-inner {
  border-radius: 25px;
  position: relative;
  height: 400px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.packages .packages-item .packages-item-inner .packages-item-inner-overlay,
.t-packages .packages-item .packages-item-inner .packages-item-inner-overlay {
  position: absolute;
  top: 0;
  border-radius: 25px;
  padding-bottom: 2rem;
  width: 100%;
  height: 100%;
  background: linear-gradient(4deg, rgba(0, 0, 0, 0.5) 32.87%, rgba(255, 255, 255, 0) 99.81%);
}

.packages .packages-item .packages-item-inner .packages-item-inner-overlay h4,
.t-packages .packages-item .packages-item-inner .packages-item-inner-overlay h4 {
  font-size: 20px;
  font-weight: 800;
  padding: 1rem 0;
}

.packages .packages-item .packages-item-inner .packages-item-inner-overlay .icon img,
.t-packages .packages-item .packages-item-inner .packages-item-inner-overlay .icon img {
  height: 30px;
  width: 30px;
  border-radius: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

.packages .packages-item .packages-item-inner .packages-item-inner-overlay .icon span,
.t-packages .packages-item .packages-item-inner .packages-item-inner-overlay .icon span {
  margin: auto;
  padding: 0 0.5rem;
}

.packages .packages-item .packages-item-inner .packages-item-inner-overlay button,
.t-packages .packages-item .packages-item-inner .packages-item-inner-overlay button {
  display: none;
  color: white;
}

.packages .packages-item .packages-item-inner .packages-item-inner-overlay:hover .icon,
.t-packages .packages-item .packages-item-inner .packages-item-inner-overlay:hover .icon {
  display: none !important;
}

.packages .packages-item .packages-item-inner .packages-item-inner-overlay:hover button,
.t-packages .packages-item .packages-item-inner .packages-item-inner-overlay:hover button {
  display: block;
}

.packages .pagination .page-item,
.t-packages .pagination .page-item {
  --bs-pagination-color: var(--color-2) !important;
  --bs-pagination-active-bg: var(--color-2) !important;
  --bs-pagination-active-border-color: var(--color-2) !important;
}

.packages .pagination .page-item .page-link:hover,
.t-packages .pagination .page-item .page-link:hover {
  color: #484848 !important;
}

.packages .pagination .page-item .page-link:focus,
.t-packages .pagination .page-item .page-link:focus {
  color: var(--color-2) !important;
  box-shadow: none !important;
}

.why {
  background: var(--color-4);
  color: white;
}

.why .row {
  padding: 150px 5rem;
}

.why h1 {
  font-size: 4.04vw;
  line-height: 3.5vw;
}

.why .why-overlay-top img {
  width: 100%;
}

.why .why-overlay-bottom img {
  width: 100%;
  transform: rotateX(180deg) rotateY(180deg);
}

.why .why-img .why-img-inner img {
  width: 100%;
  border-radius: 25px;
  height: 26vw;
  -o-object-fit: cover;
  object-fit: cover;
}

.why .why-text {
  padding: 1em;
}

.why .why-text p {
  margin-bottom: 4px;
}

.why .why-text .title {
  font-weight: 900;
}

.why .why-text ul {
  padding-top: 2rem;
  list-style-image: url("/public/assets/img/arrow.svg");
}

.why .why-text ul li {
  padding: 0.3rem 0;
  padding-left: 1.5rem;
  font-size: 1vw;
}

.why .why-text a {
  color: white;
  margin-top: 2rem;
}

.explore {
  background: url("/public/assets/img/explore-bg.png");
}

.explore .btn {
  border-radius: 0 !important;
  border: 2px solid gray;
}

.explore .btn:hover {
  color: white !important;
}

.explore strong {
  color: var(--color-2);
  font-weight: normal;
}

.testimonials {
  background: url("/public/assets/img/bg-hills1.png") no-repeat;
  background-size: 100% auto;
}

.testimonials h2 {
  font-weight: 900;
}

.testimonials .slick-prev:before,
.testimonials .slick-next:before {
  color: black !important;
}

.latest-trip {
  background: var(--color-3);
  color: white;
}

.latest-trip img {
  width: 100%;
  height: auto;
}

.latest-trip .latest-trip-left .latest-trip-left-img {
  position: relative;
}

.latest-trip .latest-trip-left .latest-trip-left-img .latest-trip-left-img-overlay {
  height: 100%;
  width: 100%;
  background: rgba(61, 61, 61, 0.3);
  position: absolute;
  bottom: 0%;
  left: 0%;
}

.latest-trip .latest-trip-left .latest-trip-left-img .latest-trip-left-img-overlay i {
  color: #cd201f;
}

.latest-trip .latest-trip-right .slick-arrow {
  display: none !important;
}

.banner {
  width: 100%;
  height: 24vw;
  min-height: 300px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: right;
}
.banner .form-control {
  border-radius: 0 !important;
}
.banner .banner-text {
  font-weight: 900;
}

footer {
  background: var(--color-4);
  color: #c2c2c2;
}
footer .title {
  color: var(--color-2);
  text-transform: uppercase;
}
footer i {
  color: var(--color-2);
  padding: 0 0.5rem;
  font-size: 1rem;
}
footer a {
  color: #c2c2c2 !important;
  text-decoration: none;
}
footer ul {
  padding: 0 !important;
}
footer ul li {
  list-style-type: none;
}
footer .footer-below {
  padding: 0.5rem 0;
}
footer .associated-img {
  padding: 1rem 0;
}
footer .associated-img img {
  width: 100%;
  border-radius: 50%;
  margin: 0 0.5rem;
}
footer .we-accept {
  padding: 0.5rem 0;
}
footer .we-accept img {
  padding: 0 0.5rem;
}
footer .footer-social .footer-social-middle a .footer-social-middle-tooltip {
  visibility: hidden;
  top: -40px;
  left: 0;
}
footer .footer-social .footer-social-middle a:hover .footer-social-middle-tooltip {
  visibility: visible;
}

.top-banner {
  height: calc(25vw + 3rem);
  background-repeat: no-repeat !important;
  background-position: 5% 45%;
  background-size: cover;
  color: white;
}

/* Contact Page */
.contact-us {
  background: linear-gradient(180deg, #fff 40%, #f9f9f9 70%, #f9f9f9 100%);
}

.contact-us .contact-us-left {
  padding: 2rem;
}

.contact-us .contact-us-left p {
  font-size: 15px;
  font-weight: 400;
  color: #484848;
  line-height: 22px;
  /* 146.667% */
  letter-spacing: 1.35px;
}

.contact-us .contact-us-left .contact-us-left-below i {
  font-size: 3rem;
  color: var(--color-2);
}

.contact-us .contact-us-left .contact-us-left-below p {
  border-left: 2px solid var(--color-2);
  padding: 2rem 1rem;
}

.contact-us .contact-us-right {
  height: -moz-fit-content;
  height: fit-content;
  background: var(--color-2);
  border-radius: 0.5rem;
  padding: 2rem;
}

.contact-us .contact-us-right h3 {
  color: white;
}

/* About Page */
.about .about-left {
  overflow: hidden;
}

.about .about-left h2 {
  font-weight: 900;
  font-size: calc(2.5vw + 1rem);
  margin: 0;
}

.about .about-left img {
  border-radius: 25px;
  width: 100%;
  height: calc(35vw + 8rem);
  -o-object-fit: cover;
  object-fit: cover;
}

.about .about-right {
  padding-top: 1rem;
  padding-left: 4rem;
}

.about .about-right p {
  color: #484848;
  text-align: justify;
  line-height: 22px;
}

.about .about-right .about-right-below {
  padding-top: 3rem;
}

.about .about-right .about-right-below .col-md-5 {
  padding-right: 3rem !important;
}

.about .about-right .about-right-below img {
  width: 100%;
  height: 25vw;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 25px;
}

.about .about-team {
  color: #484848;
  padding: 2rem 0;
}

.about .about-team h1 {
  color: var(--color-2);
  font-weight: 900;
  padding-bottom: 2rem;
}

.about .about-team .modal .modal-dialog {
  max-width: 60%;
}

@media screen and (min-width: 280px) and (max-width: 1199.9px) {
  .about .about-team .modal .modal-dialog {
    max-width: 100%;
  }
}
.about .about-team .about-team-item {
  width: 100%;
  border-radius: 16px;
  text-align: center;
  transition: 0.1s;
  background: none;
  padding: 0;
  outline: none;
}

.about .about-team .about-team-item img {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.about .about-team .about-team-item h5 {
  color: #484848;
  padding: 1rem 0.5rem;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
}

.about .about-team .about-team-item p {
  font-size: 12px;
  color: var(--color-2);
  text-transform: capitalize;
}

.about .about-team .about-team-item .about-team-item-text {
  background: white;
  border-radius: 0 0 16px 16px;
}

.about .about-team .about-team-item:hover {
  background: var(--color-2) !important;
  outline: 6px solid var(--color-2) !important;
  padding: 0;
}

.about .about-team .about-team-item:hover h5 {
  color: white;
}

.about .about-team .about-team-item:hover p {
  color: #484848;
}

.about .about-team .about-team-item:hover .about-team-item-text {
  background: var(--color-2);
}

.about .why-more .why-text ul {
  list-style-image: url("/public/assets/img/arrow.svg");
  padding-left: 4vw;
}

.about .why-more .why-text ul li strong {
  color: var(--color-2);
  font-weight: 900;
}

.about .why-more .why-text h1 {
  font-size: calc(3vw + 1rem);
  font-weight: 900;
  margin: 0;
  padding-bottom: calc(2vw + 1rem);
  color: var(--color-2);
}

.main-inner-page .main-inner-page-top {
  padding: 2rem 0;
}

.main-inner-page .main-inner-page-top .main-inner-page-top-item {
  padding: 8px 0;
  color: #484848;
}

.main-inner-page .main-inner-page-top .main-inner-page-top-item .main-inner-page-top-item-img img {
  width: 60px;
}

.main-inner-page .main-inner-page-top .main-inner-page-top-item:nth-child(1) {
  border-left: none !important;
  border-right: none !important;
}

.main-inner-page .main-inner-page-top .main-inner-page-top-item:nth-child(-n+4) {
  border-bottom: 2px solid #e5e5e5;
  border-left: 2px solid #e5e5e5;
}

.main-inner-page .main-inner-page-top .main-inner-page-top-item:nth-child(5) {
  border: none !important;
}

.main-inner-page .main-inner-page-top .main-inner-page-top-item:nth-last-child(-n+4) {
  border-left: 2px solid #e5e5e5;
}

.main-inner-page .main-inner-page-right {
  height: -moz-fit-content;
  height: fit-content;
}

.main-inner-page .main-inner-page-right .main-inner-page-right-inner {
  border-radius: 1rem;
  padding: 1.5rem;
  background: #484848;
}

.main-inner-page .main-inner-page-right .main-inner-page-right-inner .form-select {
  color: var(--bs-secondary-color);
}

.main-inner-page .main-inner-page-right .main-inner-page-right-inner h6 {
  color: white;
}

.main-inner-page .main-inner-page-right .main-inner-page-right-inner p {
  color: white;
  font-size: 13px;
}

.main-inner-page .main-inner-page-right .main-inner-page-right-inner p span {
  padding: 0 0.5rem;
  color: var(--color-2);
}

.main-inner-page .main-inner-page-right .main-inner-page-right-inner button {
  color: var(--color-2);
}

.main-inner-page .main-inner-page-right .main-inner-page-right-inner button:hover {
  color: white;
}

.main-inner-page .main-inner-page-left .slick-slider .slick-next {
  right: -2px;
  top: 49.5%;
  width: 50px;
  height: 90%;
}

.main-inner-page .main-inner-page-left .slick-slider .slick-prev {
  left: 0px;
  width: 50px;
  height: 90% !important;
  top: 49.5%;
  z-index: 1;
}

.main-inner-page .main-inner-page-left .slick-slider .slick-prev:hover,
.main-inner-page .main-inner-page-left .slick-slider .slick-prev:focus,
.main-inner-page .main-inner-page-left .slick-slider .slick-next:hover,
.main-inner-page .main-inner-page-left .slick-slider .slick-next:focus {
  color: transparent;
  outline: none;
  background: rgba(0, 0, 0, 0.3);
}

.main-inner-page .main-inner-page-left .main-inner-page-left-img-top img {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.main-inner-page .main-inner-page-left .main-inner-page-left-img-below .slick-slider {
  height: 100px !important;
  overflow: hidden;
}

.main-inner-page .main-inner-page-left .main-inner-page-left-img-below .slick-slider button {
  display: none !important;
}

.main-inner-page .main-inner-page-left .main-inner-page-left-img-below img {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.main-inner-page .main-inner-page-bottom p {
  color: #484848;
}

.main-inner-page .main-inner-page-bottom p span {
  color: var(--color-2);
  padding: 0.25rem;
  border-radius: 8px;
  font-weight: 700;
}

.main-inner-page .main-inner-page-bottom .nav-link {
  font-weight: 600;
  font-size: 20px;
  line-height: 0px;
  color: #484848 !important;
}

.main-inner-page .main-inner-page-bottom .nav .nav-link.active {
  color: white !important;
  background: var(--color-2);
}

.main-inner-page .main-inner-page-bottom .main-inner-page-bottom-img-top img {
  width: 100%;
}

.main-inner-page .main-inner-page-bottom .main-inner-page-bottom-img-bottom img {
  width: 100%;
  transform: rotateX(180deg) rotateY(180deg);
}

.main-inner-page .client-review {
  padding: 2rem;
}

.main-inner-page .client-review .rating input[type=radio] {
  display: none;
}

.main-inner-page .client-review h6 {
  color: var(--color-2);
  padding: 0;
}

.main-inner-page .client-review p {
  padding: 0;
}

.main-inner-page .related-trip {
  background: url("/public/assets/img/related-trip-bg.png");
}

.main-inner-page .related-trip .related-trip-top img {
  width: 100%;
}

.main-inner-page .related-trip .textcolor-primary {
  font-weight: 500;
}

.main-inner-page .related-trip h3 {
  color: var(--color-2);
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 4rem;
}

.main-inner-page .related-trip .related-trip-img-item {
  color: #484848;
  padding: 0 1rem;
}

.main-inner-page .related-trip .related-trip-img-item h5 {
  margin-top: 1rem;
  font-weight: 800;
  text-transform: uppercase;
}

.main-inner-page .related-trip .related-trip-img-item h6 {
  color: var(--color-5);
  font-size: 0.8rem;
}

.main-inner-page .related-trip .related-trip-img-item a {
  font-weight: 800;
}

.main-inner-page .related-trip .related-trip-img-item img {
  width: 100%;
  border-radius: 25px;
  filter: brightness(0.8);
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
}

.gallery .slick-arrow {
  display: none !important;
}

.gallery .mason-item {
  position: relative;
}

.gallery .mason-item .gallery-item-inner {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}

.gallery .mason-item .a {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}

.gallery .mason-item:hover .gallery-item-inner {
  opacity: 1;
}

.gallery .mason-item:hover .a {
  opacity: 1;
}

.gallery-listing a img {
  width: 33.33%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 250px;
  border-radius: 18px;
}

.faq .accordion {
  border-radius: 6px;
  border: 2px solid rgba(245, 139, 33, 0.462745098) !important;
}

.faq .accordion .accordion-button {
  border-bottom: 2px solid rgba(245, 139, 33, 0.462745098) !important;
  background-color: rgba(245, 139, 33, 0.462745098);
  box-shadow: none;
}

.faq .accordion .accordion-button:focus {
  box-shadow: red !important;
}

.faq .accordion .accordion-button:not(.collapsed) {
  color: white !important;
  background-color: var(--color-2);
}

@media screen and (min-width: 280px) and (max-width: 575px) {
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .nav-contact-cta {
    padding-left: 0 !important;
  }
  body {
    --bs-body-font-size: 0.8rem !important;
  }
  .hero {
    height: 100vw;
  }
  .hero .hero-cta {
    padding: 0.5rem !important;
    color: #273044 !important;
  }
  .philosophy .phil {
    padding: 50px 1rem 60px 1rem;
  }
  .philosophy .phil .phil-left .phil-left-inner-top img {
    height: 80vw;
  }
  .philosophy .phil .phil-right {
    padding: 1rem;
    padding-left: 0rem;
  }
  .philosophy .phil .phil-right .heading {
    margin: 1rem 0;
    text-align: center;
  }
  .philosophy .phil .phil-right .phil-body {
    padding: 1rem 0 2rem 0;
  }
  .packages .packages-item,
  .t-packages .packages-item {
    padding: 0.5rem;
  }
  .packages .packages-item .packages-item-inner,
  .t-packages .packages-item .packages-item-inner {
    height: 50vw !important;
  }
  .packages .packages-item .packages-item-inner .packages-item-inner-overlay h4,
  .t-packages .packages-item .packages-item-inner .packages-item-inner-overlay h4 {
    font-size: 16px;
    font-weight: 800;
    padding: 1rem 0;
  }
  .why .why-img .why-img-inner img {
    width: 100%;
    border-radius: 25px;
    height: 80vw;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .why h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .why .row {
    padding: 1rem;
  }
  .why .row .why-text .title {
    text-align: center;
  }
  .why .row .why-text ul li {
    padding: 0 1rem;
    font-size: 12px;
  }
  footer .associated-img img {
    margin: 0;
  }
  .about .about-left h2 {
    font-size: 2rem;
    padding-bottom: 2rem;
    margin: 0;
  }
  .about .about-right {
    padding-top: 0;
    padding-left: 0;
    padding: 1rem 0;
  }
  .about .about-right .about-right-below {
    padding-top: 0;
    padding: 1rem;
  }
  .about .about-right .about-right-below img {
    width: 100%;
    height: 50vw;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 25px;
  }
  .about .about-team {
    padding: 0;
  }
  .about .about-team h1 {
    padding-bottom: 1rem;
  }
  .contact-us .contact-us-left {
    padding: 1rem;
  }
  .contact-us .contact-us-left .contact-us-left-below p {
    font-size: 12px !important;
    padding: 0.5rem !important;
  }
  .main-inner-page .main-inner-page-top {
    padding: 1rem 0 2rem 0;
  }
  .main-inner-page .main-inner-page-top .main-inner-page-top-item {
    border: none !important;
  }
  .main-inner-page .main-inner-page-top .main-inner-page-top-item .main-inner-page-top-item-img img {
    width: 40px;
    height: 40px;
  }
  .main-inner-page .main-inner-page-right {
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
  }
  .main-inner-page .main-inner-page-left {
    padding: 0;
  }
  .main-inner-page .main-inner-page-bottom .nav-item {
    padding: 0;
  }
  .main-inner-page .main-inner-page-bottom .nav-item .nav-link {
    padding: 0.5rem 0.5rem;
    font-size: 0.8rem;
  }
  .gallery-listing a img {
    width: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100px;
    border-radius: 10px;
  }
  .banner h1 {
    font-size: calc(2vw + 0.75rem);
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .nav-contact-cta {
    padding-left: 0 !important;
  }
  .philosophy .phil {
    padding: 50px 1rem 60px 1rem;
  }
  .philosophy .phil .phil-left .phil-left-inner-top img {
    height: 65vw;
  }
  .philosophy .phil .phil-right {
    padding: 1rem;
    padding-left: 0rem;
  }
  .philosophy .phil .phil-right .heading {
    margin: 1rem 0;
    text-align: center;
  }
  .philosophy .phil .phil-right .phil-body {
    padding: 1rem 0 2rem 0;
  }
  .packages .packages-item,
  .t-packages .packages-item {
    padding: 2rem;
  }
  .packages .packages-item .packages-item-inner,
  .t-packages .packages-item .packages-item-inner {
    height: 50vw !important;
  }
  .packages .packages-item .packages-item-inner .packages-item-inner-overlay h4,
  .t-packages .packages-item .packages-item-inner .packages-item-inner-overlay h4 {
    font-size: 16px;
    font-weight: 800;
    padding: 1rem 0;
  }
  .why .why-img .why-img-inner img {
    width: 100%;
    border-radius: 25px;
    height: 60vw;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .why h1 {
    font-size: 2rem;
  }
  .why .row {
    padding: 2rem 1rem;
  }
  .why .row .why-text .title {
    text-align: center;
  }
  .why .row .why-text ul li {
    padding: 0 1rem;
    font-size: 12px;
  }
  footer .associated-img img {
    margin: 0;
  }
  .about .about-left h2 {
    font-size: 2rem;
    padding-bottom: 2rem;
    margin: 0;
  }
  .about .about-right {
    padding-top: 0;
    padding-left: 0;
    padding: 1rem 0;
  }
  .about .about-right .about-right-below {
    padding-top: 0;
    padding: 1rem;
  }
  .about .about-right .about-right-below img {
    width: 100%;
    height: 50vw;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 25px;
  }
  .about .about-team {
    padding: 0;
  }
  .about .about-team h1 {
    padding-bottom: 1rem;
  }
  .main-inner-page .main-inner-page-top {
    padding: 1rem 0 2rem 0;
  }
  .main-inner-page .main-inner-page-top .main-inner-page-top-item {
    border: none !important;
  }
  .main-inner-page .main-inner-page-top .main-inner-page-top-item .main-inner-page-top-item-img img {
    width: 100%;
    height: auto;
  }
  .main-inner-page .main-inner-page-left {
    padding: 2rem 1rem;
  }
  .main-inner-page .main-inner-page-bottom .nav-item {
    padding: 0;
  }
  .main-inner-page .main-inner-page-bottom .nav-item .nav-link {
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
  }
  .gallery-listing a img {
    width: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 150px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .container {
    width: 100% !important;
    max-width: 100% !important;
  }
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .nav-contact-cta {
    padding-left: 0 !important;
  }
  .philosophy .phil {
    padding: 10vw 1rem 12vw 1rem;
  }
  .philosophy .phil .phil-left .phil-left-inner-top img {
    height: 45vw;
  }
  .philosophy .phil .phil-right .heading {
    margin: 1rem 0;
  }
  .philosophy .phil .phil-right .phil-body {
    padding: 1rem 0 2rem 0;
  }
  .philosophy .phil-overlay {
    top: 50vw;
  }
  .why .why-img .why-img-inner img {
    width: 100%;
    border-radius: 25px;
    height: 60vw;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .why .row {
    padding: 100px 5rem;
  }
  .why .row .why-text {
    padding: 2em;
    padding-bottom: 0;
  }
  .why .row .why-text .title {
    text-align: center;
  }
  .why .row .why-text ul li {
    padding: 0 1rem;
    font-size: 1em;
  }
  footer .associated-img img {
    margin: 0;
  }
  .about .about-left h2 {
    font-size: 2rem;
    padding-bottom: 2rem;
    margin: 0;
  }
  .about .about-right {
    padding-top: 0;
    padding-left: 0;
    padding: 1rem 0;
  }
  .about .about-right .about-right-below {
    padding-top: 0;
    padding: 1rem;
  }
  .about .about-right .about-right-below img {
    width: 100%;
    height: 50vw;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 25px;
  }
  .about .about-team {
    padding: 0;
  }
  .about .about-team h1 {
    padding-bottom: 1rem;
  }
  .main-inner-page .main-inner-page-top {
    padding: 1rem 0 2rem 0;
  }
  .main-inner-page .main-inner-page-top .main-inner-page-top-item {
    border: none !important;
  }
  .main-inner-page .main-inner-page-top .main-inner-page-top-item .main-inner-page-top-item-img img {
    width: 100%;
    height: 50px;
  }
  .main-inner-page .main-inner-page-left {
    padding: 2rem 1rem;
  }
  .main-inner-page .main-inner-page-bottom .nav-item {
    padding: 0;
  }
  .main-inner-page .main-inner-page-bottom .nav-item .nav-link {
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
  }
  .gallery-listing a img {
    width: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 200px;
    border-radius: 14px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .philosophy .phil-overlay {
    top: 44vw;
  }
  .why .why-img .why-img-inner img {
    width: 100%;
    border-radius: 25px;
    height: 35vw;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .why .row {
    padding: 120px 4rem;
  }
  .why .row .why-text {
    padding: 2em;
    padding-bottom: 0;
  }
  .why .row .why-text a {
    color: white;
    margin-top: 1rem;
  }
  .why .row .why-text ul li {
    padding-left: 2rem;
    font-size: 1em;
  }
  footer .associated-img img {
    margin: 0;
  }
  .about .about-right .about-right-below img {
    width: 100%;
    height: 225px;
  }
  .main-inner-page .main-inner-page-top .main-inner-page-top-item .main-inner-page-top-item-img img {
    width: 60px;
    height: auto;
  }
  .main-inner-page .main-inner-page-bottom .nav-item {
    padding: 0;
  }
  .main-inner-page .main-inner-page-bottom .nav-item .nav-link {
    padding: 0.5rem 0.5rem;
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1999.98px) {
  body {
    font-size: 20px !important;
  }
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .philosophy .container {
    width: calc(100vw - 2rem) !important;
    max-width: 95% !important;
  }
  .philosophy .phil-overlay {
    top: 42vw;
  }
  .philosophy .phil {
    padding: 5vw 1rem;
  }
  .philosophy .phil .phil-left .phil-left-inner-top img {
    height: 45vw;
  }
  .why .container {
    width: 100% !important;
    max-width: 100% !important;
  }
  .why .row {
    padding: 5vw;
  }
  .why .row .why-text {
    padding: 1rem;
    padding-bottom: 0;
  }
  .why .row .why-text ul {
    padding-left: 3rem;
  }
  .why .row .why-text ul li {
    padding: 0 1rem;
    font-size: 1em;
  }
  footer .associated-img img {
    margin: 0;
  }
  .about .about-right .about-right-below img {
    width: 100%;
    height: 225px;
  }
  .main-inner-page .main-inner-page-top .main-inner-page-top-item .main-inner-page-top-item-img img {
    width: 60px;
    height: auto;
  }
  .main-inner-page .main-inner-page-bottom .nav-item {
    padding: 0;
  }
  .main-inner-page .main-inner-page-bottom .nav-item .nav-link {
    padding: 0.5rem 0.5rem;
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 2000px) and (max-width: 2999.98px) {
  body {
    font-size: 32px !important;
  }
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .philosophy .container {
    width: calc(100vw - 2rem) !important;
    max-width: 95% !important;
  }
  .philosophy .phil-overlay {
    top: 42vw;
  }
  .philosophy .phil {
    padding: 5vw 1rem;
  }
  .philosophy .phil .phil-left .phil-left-inner-top img {
    height: 45vw;
  }
  .why .container {
    width: 100% !important;
    max-width: 100% !important;
  }
  .why .row {
    padding: 5vw;
  }
  .why .row .why-text {
    padding: 1rem;
    padding-bottom: 0;
  }
  .why .row .why-text .title {
    text-align: center;
  }
  .why .row .why-text ul {
    padding-left: 5em;
  }
  .why .row .why-text ul li {
    padding: 0 1rem;
    font-size: 1em;
  }
  footer .container {
    width: calc(100vw - 2rem) !important;
    max-width: 95% !important;
  }
  footer .associated-img img {
    margin: 0;
  }
  .about .container {
    width: calc(100vw - 2rem) !important;
    max-width: 90% !important;
  }
  .about .about-right p {
    color: #484848;
    text-align: justify;
    line-height: inherit;
  }
  .about .about-left img {
    border-radius: 25px;
    width: 100%;
    height: 40vw;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .main-inner-page .main-inner-page-top .main-inner-page-top-item .main-inner-page-top-item-img img {
    width: 100px;
    height: auto;
  }
  .main-inner-page .main-inner-page-bottom .nav-item {
    padding: 0;
  }
  .main-inner-page .main-inner-page-bottom .nav-item .nav-link {
    padding: 0.5rem 0.5rem;
    font-size: 1.5rem;
  }
}
nav .logo img {
  height: calc(5vw + 3rem);
}

i.bx {
  font-size: 1rem;
  vertical-align: -0.125em;
}

/*# sourceMappingURL=style.css.map */